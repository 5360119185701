import {
  ref,
  push,
  set,
  get,
  update,
  remove,
  serverTimestamp,
} from "firebase/database";
import { ref as storeRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { FlowElement } from "react-flow-renderer";
import { v4 as uuidv4 } from 'uuid'

import { firebaseDB, firebaseStorage } from "@/services/firebase";

const normalizeFlow = (elements: FlowElement[]) => {
    return JSON.parse(JSON.stringify(elements))
}

export const createFlow = async (
  userID: string | undefined,
  name: string,
  elements: FlowElement[]
) => {
  if (!userID) {
    return
  }

  try {
    const flowRef = ref(firebaseDB, `users/${userID}/flows`);
    const newFlowRef = push(flowRef);
    const storageRef = storeRef(firebaseStorage, `${userID}/audio`)

    const uploadedElements = await Promise.all(elements.map(async elem => {
        if (elem.data?.blobURL && elem.data?.blobURL.includes('blob')) {
            let blob = await fetch(elem.data.blobURL).then(r => r.blob());
            const blobID = uuidv4()
            const snapshot = await uploadBytes(storeRef(storageRef, blobID), blob) // ,{contentType:"audio/mp3"}
            let url = await getDownloadURL(storeRef(storageRef, blobID))
            // console.log("This is the mp3 url:", url);
            // url = url + '.mp3';
            elem.data.blobURL = url ;
            
            //console.log("This is the mp3 url after add:", elem.data.blobURL);
            elem.data.blobPath = snapshot.metadata.fullPath
        }
        return elem
    }))

    set(newFlowRef, { elements: normalizeFlow(uploadedElements), name, createdAt: serverTimestamp() });

    return newFlowRef.key;
  } catch (err) {
    console.log(err);
  }
};

export const updateFlow = async (
  userID: string | undefined,
  flowID: string,
  elements: FlowElement[],
  name?: string
) => {
  try {
    const flowRef = ref(firebaseDB, `users/${userID}/flows/${flowID}`);
    const current = await get(flowRef);
    const hasBeenCreated = !!current.val();

    const storageRef = storeRef(firebaseStorage, `${userID ?? 'anonymous'}/audio`)
    const uploadedElements = await Promise.all((elements ?? []).map(async elem => {
        if (elem.data?.blobURL && elem.data?.blobURL.includes('blob')) {
            let blob = await fetch(elem.data.blobURL).then(r => r.blob());
            const blobID = uuidv4()
            const snapshot = await uploadBytes(storeRef(storageRef, blobID), blob) // ,{contentType:"audio/mp3"}
            let url = await getDownloadURL(storeRef(storageRef, blobID))
            //console.log("This is the mp3 url:", url);
            // url = url + '.mp3';
            elem.data.blobURL = url ;
            //console.log("This is the mp3 url after add:", elem.data.blobURL);
            elem.data.blobPath = snapshot.metadata.fullPath
        }
        return elem
    }))

    update(
      flowRef,
      hasBeenCreated
        ? {
            elements: normalizeFlow(uploadedElements),
            updatedAt: serverTimestamp(),
          }
        : {
            elements: normalizeFlow(uploadedElements),
            createdAt: serverTimestamp(),
            name: name ?? 'Untitled',
          }
    );
  } catch (err) {
    console.log(err);
  }
};

export const removeFlow = async (
  userID: string | undefined,
  flowID: string,
) => {
  if (!userID) {
    return
  }
  
  try {
    const flowRef = ref(firebaseDB, `users/${userID}/flows/${flowID}`);
    await remove(flowRef)
  } catch (err) {
    console.log(err);
  }
};

export const getFlows = async (userID?: string) => {
  if (!userID) {
    return {}
  }
  
  try {
    const flowRef = ref(firebaseDB, `users/${userID}/flows`);
    const current = await get(flowRef);
    return current.val();
  } catch (err) {
    console.log(err);
  }
};

// export const getFlows = async (userID?: string) => {
//   if (!userID) {
//     return {}
//   }

//   try {
//     const flowRef = ref(firebaseDB, `users/${userID}/flows`);
//     const snapshot = await get(flowRef);
//     const flows = snapshot.val();

//     // 转换为数组并排序
//     const sortedFlowsArray = Object.entries(flows || {}).sort((a, b) => {
//       const flowA = a[1];
//       const flowB = b[1];
//       return (flowA.createdAt || 0) - (flowB.createdAt || 0);
//     });

//     // 如果你想保持对象的形式，转换回对象
//     const sortedFlows = sortedFlowsArray.reduce((acc, [key, value]) => {
//       acc[key] = value;
//       return acc;
//     }, {});

//     return sortedFlows;
//   } catch (err) {
//     console.log(err);
//     return {}; // 在出错时返回一个空对象
//   }
// };


export const getTargetFlow = async (userID: string, flowID: string) => {
  try {
    const flowRef = ref(firebaseDB, `users/${userID}/flows/${flowID}`);
    const current = await get(flowRef);
    return current.val();
  } catch (err) {
    console.log(err);
  }
};
